import { useState, useEffect } from 'react';

import MainTitle from '../../components/MainTitle';
import { faCirclePlus, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Webinars = () => {
  const axios = useAxiosPrivate();

  return (
    <section className='text-gray-700'>
      <MainTitle title="Services" />
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4">
        <button
          onClick={() => window.location.href = '/courses'}
          className="bg-violet-500 text-white py-2 px-4 rounded-md h-32 w-32 flex flex-col items-center justify-center hover:bg-violet-300"
        >
          Courses
          <FontAwesomeIcon icon={faVideo} className="text-2xl mt-2" />
        </button>
      </div>
    </section>
  );
}

export default Webinars;

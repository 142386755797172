/* eslint-disable */
import { useState, useEffect } from 'react'

import RequestNotes from './Partials/RequestNotes'
import PPQInfo from './Partials/PPQInfo'
import TaskTitle from '../../components/TaskTitle'
import TaskActions from './Partials/TaskActions'
import moment from 'moment'

const PPQReview = ({task}) => {

  const [taskTitle, setTaskTitle] = useState('')
  const [refreshTrigger, setRefreshTrigger] = useState(0)
  const [schoolInfo, setSchoolInfo] = useState([])
  const [staffDetails, setStaffDetails] = useState([])
  const [questionInfo, setQuestionInfo] = useState([])
  const [requestData, setRequestData] = useState({})

  useEffect(() => {
    const request_information = task.task_info.request_information;
    const formData = request_information.form_data;
    let schoolInfo = [];
    let staffDetails = [];
    let questionInfo = [];

    let requestDataArray = {
      wbs_case_id: request_information.wbs_case_id,
      claim_reference: request_information.claim_reference
    };
    for (const [key, value] of Object.entries(formData)) {
      if (!value.visible) continue;

      switch (value.group) {
        case 0:
          schoolInfo[value.order] = { title: value.title, value: value.value };
          break;
        case 1:
          staffDetails[value.order] = { title: value.title, value: value.value };
          break;
        case 2:
          questionInfo[value.order] = { title: value.title, value: value.value };
          break;
        default:
        break;
      }

      switch (value.title) {
        case 'School Contact Name':
          requestDataArray.employer = value.value;
          break;
        case 'School Contact Email':
          requestDataArray.employerEmail = value.value;
          break;
        case 'Forename':
          requestDataArray.employeeName = `${value.value} ${requestDataArray.employeeName || ''}`;
          break;
        case 'Surname':
          requestDataArray.employeeName = `${requestDataArray.employeeName || ''}${value.value}`;
          break;
        case 'Date of Birth':
          requestDataArray.employeeDOB = moment(value.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
          break;
        case 'Role':
          requestDataArray.employeeRole = value.value;
          break;
        default:
        break;
      }
    }

    setRequestData(requestDataArray)
    setSchoolInfo(schoolInfo.filter(Boolean));
    setStaffDetails(staffDetails.filter(Boolean));
    setQuestionInfo(questionInfo.filter(Boolean));

  }, [])

  useEffect(() => {
      const task_info = task?.task_info;
      const request_information = task_info.request_information;
      const claim_reference = request_information.claim_reference;

      const title = `PPQ Review${claim_reference ? ` - ${claim_reference}` : ''}`
      setTaskTitle(title);
  }, [task])

  const refreshNotes = () => {
    setRefreshTrigger(refreshTrigger + 1)
  }

  return (
    <>
      <div className='text-gray-600 max-w-[74rem] 2xl:max-w-none'>
        <div className='w-full'>
          <TaskTitle
            task={task}
            title={taskTitle}
            backButton
          />
        </div>
        <div className='flex w-full space-x-4'>
          <div className="w-2/3">
            <PPQInfo task={task} schoolInfo={schoolInfo} staffDetails={staffDetails} questionInfo={questionInfo}/>
          </div>
          <div className='w-1/3'>
            <TaskActions task={task} requestData={requestData} onCreatedNote={refreshNotes} />
            <RequestNotes task={task} refreshNotes={refreshTrigger}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default PPQReview
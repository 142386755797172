import { useState, useEffect } from 'react'
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import moment from 'moment'

const CreateReferral = ({ type, id = null, closeSlideout }) => {
    const axios = useAxiosPrivate();
    const { createInfo } = useAuth()

    const [searchQuery, setSearchQuery] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [selectedSchool, setSelectedSchool] = useState(null)
    const [selectedStaff, setSelectedStaff] = useState(null)

    const [activeTab, setActiveTab] = useState(null);
    const [policies, setPolicies] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [policyStaff, setPolicyStaff] = useState(null);

    const [loadingSchools, setLoadingSchools] = useState(false)
    const [loadingStaff, setLoadingStaff] = useState(false)

    useEffect(() => {
        setSearchResults([])
        if (searchQuery.length >= 3) {
            setLoadingSchools(true)
            runSearch()
        }
    }, [searchQuery])

    const runSearch = async () => {
        try {
            let kisResults = [];
            const url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/search/org?api_token=${process.env.REACT_APP_API_KEY}`;

            const kisResponse = await axios.post(url, { q: searchQuery });
            kisResults = kisResponse?.data?.data || [];
    
            setSearchResults(kisResults);
        } catch (error) {
            console.error("Error during search:", error);
            setSearchResults([]);
        } finally {
            setLoadingSchools(false);
        }
    };

    const formatSelectedSchool = (school) => {
        let selectedSchoolData = school;
        let information = [];
        if(school.academy_information) {
            information = JSON.parse(school.academy_information);
        } else {
            information = JSON.parse(school.trust_information);
        }

        selectedSchoolData['information'] = information;

        if(school.policys) {
            setPolicies(school.policys.filter(item => (item.status === 'live'|| item.status === 'expiring') && item.department !== 'AIS' && item.product !== 'SWB Essential'));
        } else {
            setPolicies([]);
        }

        setSelectedSchool(selectedSchoolData);
    }
    
    const handleTabChange = async (item) => {
        setActiveTab(item.reference);
        setSelectedPolicy(item);
        setLoadingStaff(true);

        try {
            const url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/get-policy-staff?api_token=${process.env.REACT_APP_API_KEY}`;
            const policy_staff = await axios.post(url, { policy_id: item.id });
            setPolicyStaff(policy_staff.data.policy_staffs);
        } catch (error) {
            console.error("Error during fetching policy staff:", error);
            setPolicyStaff([]);            
        } finally {
            setLoadingStaff(false);
        }
    }

    const createReferral = async (e) => {
        e.preventDefault();
        if(!selectedStaff) {
            createInfo('error', `Please select a staff member`); 
            return;
        }

        if(type === 'hhy') {
            const staff = policyStaff.find((item) => item.id === selectedStaff);

            let claim_data = {
                claim_reference: 'WGT',
                case_description: 'HHY',
                condition_detail: null,
                case_status: 'in progress',
                case_type: 'Staff',
                staff_id: staff.id,
                policy_id: staff.policy_id,
                policy_staff_id: staff.policy_staff_id,
                claim_type: type,
            };
    
            try {            
                const response = await axios.post('/claims/create-case-and-claim', claim_data);
    
                if (response.data.error === true) {
                    createInfo('error', 'Failed to Create Referral')
                    closeSlideout();
                    return;
                }
    
                let client_data = {
                    id: staff.id,
                    name: staff.name,
                    email: staff?.email ?? null,
                    date_of_birth: staff.dob,
                    school_urn: selectedSchool.unique_reference,
                    school_name: selectedSchool.name,
                    policys: selectedSchool.policys ?? [],
                    policy_id: staff.policy_id,
                }
    
                const clientResponse = await axios.post('clients/exists', {data: client_data})
    
                if (clientResponse.data.error === true) {
                    createInfo('error', 'Failed to Create Client')
                    closeSlideout();
                    return;
                }
    
                let client_id = clientResponse.data.result;
    
                const webinarUpdateResponse = await axios.post('webinars/link-client', {client_id: client_id, task_id: id, claim_reference: response['claim_reference']});
    
                if (webinarUpdateResponse.data.error === true) {
                    createInfo('error', 'Failed to Link Client')
                    closeSlideout();
                    return;
                }
                const completedResponse = await axios.post(`/tasks/${id}/completed`, {
                    completed: 1,
                    bypass_insurance: true
                });
        
                if (completedResponse.data.error === true) {
                    createInfo('error', `Failed to mark task as Completed`);
                    closeSlideout();
                    return;
                }

                createInfo('success', `Created Referral`);
            } catch (error) {
                console.error(error.message);
                createInfo('error', `Failed to create Referral`);
            } finally {
                closeSlideout();
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            }
        }
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className="mt-4 flex align-center items-center">
                <h2 className="font-bold text-xl">Search School:</h2>
                {loadingSchools && <FontAwesomeIcon icon={faSpinner} className="animate-spin ml-2" />}
            </div>
            <div className="mt-4 flex justify-center">
                <div className={`flex items-center border rounded-md px-4 bg-white transition-all duration-300 w-full opacity-100`} >
                    <input
                        type="text"
                        id="main_search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full h-9 pl-2 text-sm text-slate-800 focus:outline-none"
                        placeholder="Search"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                    />
                    <span
                        className="cursor-pointer ml-2"
                        onClick={() => {
                            setSearchQuery("");
                            setSearchResults([]);
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </div>

                {searchResults && searchResults.length > 0 ? (
                    <div className="absolute bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px] z-10 overflow-y-scroll" style={{ top: '197px', left: '53px' }} >
                        <ul className='max-h-[500px]'>
                            {searchResults.map((result, index) => (
                                <li key={result.id || index} className={`flex flex-col p-3 bg-blue-100 rounded mb-2 hover:bg-blue-200/80 cursor-pointer ${result.id ? 'bg-blue-100 hover:bg-blue-200/80' : 'bg-violet-100 hover:bg-violet-200/80'}`}
                                    onClick={() => {formatSelectedSchool(result); setSearchQuery(''); setSearchResults([])}}
                                >
                                    <span className="font-bold text-lg">{result.name}</span>
                                    <div className="flex">
                                        <span className="font-semibold mr-1">Type:</span>
                                        {result?.type === "1" ? 'Academy' : (result.type === "2" ? 'Trust' : 'Company')}
                                    </div>
                                    <div className="flex">
                                        <span className="font-semibold mr-1">Reference:</span>
                                        {result?.unique_reference}
                                    </div>
                                    <div className="flex">
                                        <span className="font-semibold mr-1">Postcode:</span>
                                        {result?.postcode}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : searchQuery.length > 0 ? (
                    <div className="absolute bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px] z-10 overflow-y-scroll" style={{ top: '197px', left: '53px' }} >
                        <ul>
                            <li className="flex flex-col p-3 bg-light-purple rounded mb-2 hover:bg-[#E5D5EF] cursor-pointer">
                                <span className="font-bold text-lg">No results found</span>
                            </li>
                        </ul>
                    </div>
                ) : null}
            </div>
            
            {selectedSchool && (
                <div className="border-t border-gray-300 mt-4">
                    <div className="mt-4 mb-2">
                        <h2 className="font-bold text-lg">Selected School:</h2>
                    </div>
                    <div className="ml-2">
                        <div>
                            <h2 className="font-semibold">School Name:</h2>
                            <p className="ml-2">{selectedSchool.name}</p>
                        </div>
                        <div>
                            <h2 className="font-semibold">School Postcode:</h2>
                            <p className="ml-2">{selectedSchool.postcode}</p>
                        </div>
                        {selectedSchool.type === "1" && (
                            <div className="mt-4">
                                <h2 className="font-semibold">Trust Name:</h2>
                                <p className="ml-2">{selectedSchool.academy_trust_name ?? 'No Trust'}</p>
                            </div>
                        )}
                    </div>
                    <div className="border-t border-gray-300 mt-4">
                        <h2 className="font-bold text-xl mt-4 mb-2">Select Policy:</h2>
                    </div>
                    <div className="grid grid-cols-4 gap-2 w-full">
                        {policies && policies.length > 0 ? (
                            <>
                                {policies.map((item) => (
                                    <button key={item.reference} className={`tab-btn${activeTab === item.reference ? " active" : ""}`} onClick={() => handleTabChange(item)}>
                                        {item.reference}
                                    </button>
                                ))}
                            </>
                        ) : (
                            <p>No Policies Found</p>
                        )}
                    </div>
                </div>
            )}

            <div className="mt-4 w-full">
                {policyStaff && (
                    <div className="border-t border-gray-300 mt-4">
                        <div className="mt-4 mb-2 flex align-center items-center">
                            <h2 className="font-bold text-xl">Select Staff:</h2>
                            {loadingStaff && <FontAwesomeIcon icon={faSpinner} className="animate-spin ml-2" />}
                        </div>
                        {policyStaff.length > 0 ? (
                            <select
                                className="w-full h-9 border rounded-md pl-2 text-sm text-slate-800 focus:outline-none"
                                onChange={(e) => setSelectedStaff(e.target.value)}
                            >
                                <option value="">Select Staff</option>
                                {policyStaff.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}{item.dob && ` - ${moment(item.dob).format('DD/MM/YYYY')}`}</option>
                                ))}
                            </select>
                        ) : (
                            <p>No Staff Found</p>
                        )}
                    </div>
                )}
            </div>

            <div className="flex items-center mt-2">
                <button type="submit" className="btn mt-4 mr-4 w-1/2" onClick={(e) => { e.preventDefault(); createReferral(e) }}>
                    Create Referral
                </button>

                <button type="button" className="btn red mt-4 w-1/2" onClick={() => closeSlideout()}>
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default CreateReferral
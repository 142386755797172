import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'

import Modal from '../../../components/Modal'
import CreateAppointment from './CreateAppointment'
import CreateReferralSlideout from './CreateReferralSlideout'
import OHResultForm from '../../../components/OHResultForm'

const TaskActions = ({task, requestData = null, onNoteCreated }) => {
    const axios = useAxiosPrivate();
    const { setShowLoader, createInfo, can } = useAuth()
  
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalType, setModalType] = useState('')
    const [open, setOpen] = useState(false)

    const [noteBody, setNoteBody] = useState({})
    const [noteType, setNoteType] = useState(false)
    const [bypassInsurance, setBypassInsurance] = useState(false)
    const [rejectionReason, setRejectionReason] = useState('')

    useEffect(() => {
      if(bypassInsurance) {
        setRejectionReason('')
      }
    }, [bypassInsurance])

    const ModalBody = () => {
      if(modalType === 'Add') {
        return <AddNoteElement />
      } else if(modalType === 'Complete') {
        if(task.type === 'ppq') {
          return <OHResultForm requestData={requestData} resetModal={resetModal} taskId={task.id} />
        } else {
          return <CompleteTaskElement />
        }
      } else if (modalType === 'Appointment') {
        return <CreateAppointment resetModal={resetModal} task={task} setShowLoader={setShowLoader} />
      }
    }

    const CompleteTaskElement = () => {
      const completeTask = async (e) => {
        e.preventDefault();
        setShowLoader(true);
        try {
          const response = await axios.post(`/tasks/${task.id}/completed`, {
            bypass_insurance: bypassInsurance,
            rejection_reason: rejectionReason,
            completed: 1,
          });
    
          if (response.data.error === false) {
            createInfo('success', `Task Completed`);
            resetModal();
            setTimeout(() => {
              window.location.reload();
            })
          }
        } catch (error) {
          console.error(error.message);
          createInfo('error', `Failed to complete task`);
        }
        setShowLoader(false);
      };
  
      return (
        <form onSubmit={completeTask}>
            <div className="flex flex-col w-full space-x-4">
              <div className="flex justify-center items-center mb-4">
                <p className="text-lg font-semibold">Are you sure you want to complete this task?</p>
              </div>
              {task.type === 'hhy' && (
                <>
                  <div className="flex justify-center items-center mb-4">
                    <input
                      type="checkbox"
                      id="grantAccessCheckbox"
                      className="mr-2"
                      onChange={(e) => setBypassInsurance(e.target.checked)}
                      checked={bypassInsurance}
                    />
                    <label htmlFor="grantAccessCheckbox" className="text-sm">
                      Do you still want to grant access to the course?
                    </label>
                  </div>
                  { !bypassInsurance && (
                    <div className="flex flex-col mb-4">
                      <label htmlFor="coverStatus" className="text-sm font-semibold mb-2">
                        Select Reason:
                      </label>
                      <select
                        id="coverStatus"
                        className="px-4 py-2 border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500"
                        onChange={(e) => setRejectionReason(e.target.value)}
                        required={!bypassInsurance}
                        value={rejectionReason}
                      >
                        <option value="" disabled selected>
                          -- Select an option --
                        </option>
                        <option value="1">School No Longer Has Cover</option>
                        <option value="2">School Never Had Cover</option>
                        <option value="3">School Has Cover but not for you</option>
                      </select>
                    </div>
                  )}
                </>
              )}
              <div className="flex space-x-4 mt-4">
                <button type="submit" className="btn w-1/2">Complete</button>
                <button type="button" className="btn red w-1/2" onClick={resetModal}>Cancel</button>
              </div>
          </div>
        </form>
      )
    }
  
    const AddNoteElement = () => {
      const createNote = async (e) => {
        e.preventDefault();
        setShowLoader(true);
        try {
          const response = await axios.post(`/tasks/${task.id}/notes/create`, {
            note_type_id: noteType ? 2 : 1,
            body: noteBody.body
          });
    
          if (response.data.error === false) {
            createInfo('success', `Note Added`);
            setNoteBody({});
            
            setTimeout(() => {
              onNoteCreated();
            }, 2000);
          }
        } catch (error) {
          console.error(error.message);
          createInfo('error', `Failed to create note`);
        }
        resetModal();
        setShowLoader(false);
      };
  
      return (
        <form onSubmit={createNote}>
          <label className='text-xs' htmlFor="">Description <span className='text-red-500'>*</span></label>
          <textarea
            value={noteBody.body}
            onChange={(e) => noteBody.body = e.target.value}
            required
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500"
          ></textarea>
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              defaultChecked={can('access nurse notes') ? true : false}
              onChange={(e) => setNoteType(e.target.checked)}
              className="mr-2"
            />
            <label className="text-xs" htmlFor="">Nurse note</label>
          </div>
          <div className="text-xs mt-2 flex justify-end">
            <span className="text-red-500">*</span> denotes required fields.
          </div>
          <div className="flex mt-2 w-full">
            <button type="submit" className="btn mt-4 mr-4 w-1/2">Add Note</button>
            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </form>
      )
    }
  
    const openCreateAppointmentModal = () => {
      setShowModal(true)
      setModalType('Appointment')
      setModalTitle('Create Appointment')
    }
  
    const openCompleteReviewModal = () => {
      setShowModal(true)
      setModalType('Complete')
      if(task.type === 'ppq') {
        setModalTitle('Complete Review')
      } else {
        setModalTitle('Complete Task')
      }
    }
  
    const openAddNoteModal = () => {
      setShowModal(true)
      setModalType('Add')
      setModalTitle('Add Note')
    }
  
    const resetModal = () => {
      setShowModal(false)
      setModalTitle('')
    }

    const openCreateReferral = () => {
      setOpen(true)
    }

    return (
        <>
          <CreateReferralSlideout open={open} setOpen={setOpen} id={task.id} type={task.type} />
          {showModal && (
              <Modal 
                  title={modalTitle} 
                  body={<ModalBody />} 
                  show={resetModal} 
                  width={modalTitle === 'Complete Review' ? '650px' : undefined} 
              />
          )}
          <div className="w-full bg-white rounded-xl shadow-md p-4 mb-4">
              <h2 className="font-bold text-xl mb-2">Actions</h2>
              
              { task.completed !== 1 || task.status !== 'Completed' ? (
                  <>
                    {task.type === 'ppq' && (
                      <button className="bg-nav hover:bg-nav-dark text-white font-bold py-2 px-4 rounded mr-2" onClick={() => openCreateAppointmentModal()} >
                        Create Appointment
                      </button>
                    )}
                    <button className="bg-nav hover:bg-nav-dark text-white font-bold py-2 px-4 rounded mr-2" onClick={() => openAddNoteModal()} >
                      Add Note
                    </button>
                    {task.type === 'hhy' && (
                      <button className="bg-nav hover:bg-nav-dark text-white font-bold py-2 px-4 rounded mr-2 mb-2" onClick={() => openCreateReferral()} >
                        Create Referral
                      </button>
                    )}
                    <button className="bg-nav hover:bg-nav-dark text-white font-bold py-2 px-4 rounded" onClick={() => openCompleteReviewModal()} >
                      {task.type === 'ppq' ? 'Complete Review' : 'Complete Task'}
                    </button>
                  </>
                ) : (
                  <>
                    { task.type === 'ppq' ? (
                      <p>The result has been given and can no longer be changed</p>
                    ) : (
                      <p>This task has been completed</p>
                    )}
                  </>
                )
              }
          </div>
        </>
    )
}

export default TaskActions
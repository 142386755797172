/* eslint-disable */
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PPQReview from './PPQReview'
import WebinarTaskView from './WebinarTaskView'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const TaskView = () => {
  const axios = useAxiosPrivate();
  const { taskId, requestId = null } = useParams()

  const [task, setTask] = useState(null)
  const [type, setType] = useState(null)

  const getTask = async () => {
    let response = await axios.get(`tasks/${taskId}/get-task-info`);
    setTask(response.data.result);
    setType(response.data.result.type);
  }

  useEffect(() => {
    getTask()
  }, [])

  const TaskElement = () => {
    switch (type) {
      case 'ppq':
        return <PPQReview task={task} />
      case 'hhy':
        return <WebinarTaskView task={task} />
      default:
        return (
          <div>Task not found</div>
        )
    }
  }

  return (
    <section>
      <TaskElement />
    </section>
  )
}

export default TaskView
import { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import moment from 'moment';

const CreateWebinar = ({onWebinarCreation, resetModal}) => {
    const handleWebinarCreation = (newWebinars) => {
        onWebinarCreation(newWebinars)
        resetModal()
    }
    const { setShowLoader } = useAuth()
    const axios = useAxiosPrivate()

    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState('');
    const [recurring, setRecurring] = useState(false);
    const [reportId, setReportId] = useState('');
    const [courseInviteLink, setCourseInviteLink] = useState('');
    
    const create = async (e) => {
        e.preventDefault()
        setShowLoader(true)

        let data = {
            title: title,
            start_date: startDate,
            recurring: recurring,
            report_id: reportId,
            course_invite_link: courseInviteLink
        }

        try {
            const response = await axios.post(`webinars/create`, data);

            if (response.data.error !== true) {
                handleWebinarCreation(response.data)
                resetModal()
            }
        } catch (error) {
            console.error(error)
        }
        setShowLoader(false)
    }

    return (
      <form onSubmit={create}>
        <div className="mb-3">
          <label htmlFor="title" className="block font-medium text-gray-600 text-sm" >
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <select
                name="title"
                id="title"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                onChange={(e) => setTitle(e.target.value)}
                required>
              <option value=""> - Please Select - </option>
              <option value="3 Week Weight Management Programme">3 Week Weight Management Programme</option>
              <option value="4 Week Mastering Mindfulness Programme">4 Week Mastering Mindfulness Programme</option>
              <option value="6 Week Wellness Programme">6 Week Wellness Programme</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="block font-medium text-gray-600 text-sm">
            Start Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              name="date"
              id="date"
              min={moment().format('YYYY-MM-DD')}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3 flex items-center">
          <div className="mt-1 relative rounded-md mr-2">
            <input
              type="checkbox"
              name="recurring"
              id="recurring"
              onChange={(e) => setRecurring(e.target.checked)}
            />
          </div>
          <label
            htmlFor="recurring"
            className="block font-medium text-gray-600 text-sm"
          >
            Is this a recurring course?
          </label>
        </div>
        <div className="mb-3">
          <label
            htmlFor="report_id"
            className="block font-medium text-gray-600 text-sm"
          >
            Report ID
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="text"
              name="report_id"
              id="report_id"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              onChange={(e) => setReportId(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="report_id"
            className="block font-medium text-gray-600 text-sm"
          >
            Course Invite Link
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="text"
              name="course_invite_link"
              id="course_invite_link"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              onChange={(e) => setCourseInviteLink(e.target.value)}
            />
          </div>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
}

export default CreateWebinar
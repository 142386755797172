import { faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import TaskAssignmentWidget from '../pages/Admin/Partials/TaskAssignmentWidget.js';

const TaskTitle = ({ title, backButton = false, task }) => {

  const goBack = () => {
    window.location.href = '/'
  }

  return (
    <div className="pb-4 text-gray-700">
      <div className='flex justify-between items-center pb-4'>
        {backButton && (
          <span
            className="inline-flex items-center text-slate-500 mt-1 cursor-pointer hover:text-slate-600"
            onClick={goBack}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
            <span className="text-sm ml-2 font-bold">Go Back</span>
          </span>
        )}
      </div>

      <div className='bg-white rounded-lg p-4 flex justify-between text-gray-700'>
        <div >
          <div className='flex items-start justify-between'>
            <div>
              <h2 className='font-semibold text-3xl flex items-center'> 
                {title}
              </h2>
              {task?.created_at && (
                <div className='flex items-center space-x-4 text-sm text-gray-500'>
                  <div>Submission Date: {moment(task.created_at).format('DD/MM/YYYY')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="flex space-x-4">
            <TaskAssignmentWidget task={task} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskTitle
import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../../hooks/useAuth';
import moment from 'moment';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import Modal from '../../../components/Modal';

const RequestNotes = ({ task, refreshNotes }) => {
  const axios = useAxiosPrivate();
  const { createInfo, can } = useAuth()
  
  const [showModal, setShowModal] = useState(false)

  const [loadingNotes, setLoadingNotes] = useState(false)
  const [generalNotes, setGeneralNotes] = useState([])
  const [nurseNotes, setNurseNotes] = useState([])
  const [selectedNote, setSelectedNote] = useState(null)
  const [isNurse, setIsNurse] = useState(can('access nurse notes'))
  
  const getAllNotes = async () => {
    try {
      setLoadingNotes(true)
      const response = await axios.get(`tasks/${task.id}/get-all-notes`)

      if (!response.data.error) {
        let data = response.data.result;
        let gNotes = data.generalNotes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        let nNotes = data.nurseNotes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setGeneralNotes(gNotes);
        setNurseNotes(nNotes);
      }
    } catch(error){
      console.error(error);
    } finally {
      setLoadingNotes(false);
    }
  }

  const getGeneralNotes = async () => {
    try {
      const response = await axios.get(`tasks/${task.id}/get-general-notes`)

      if (!response.data.error) {
        let data = response.data.result
        let gNotes = data.sort((a,b) => new Date(b.created_at) - new Date(a.created_at));
        setGeneralNotes(gNotes);
      }
    } catch(error){
      console.error(error);
    } finally {
      setLoadingNotes(false);
    }
    
  }

  const DeleteNoteElement = () => {
    const destroyNote = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`/notes/delete/${selectedNote?.id}`);
        if (response.status === 200) {
          createInfo('success', 'Note deleted successfully');
          if (selectedNote.note_type_id === 1) {
            setGeneralNotes(generalNotes => generalNotes.filter(n => n.id !== selectedNote.id));
          } else if (selectedNote.note_type_id === 2) { 
            setNurseNotes(nurseNotes => nurseNotes.filter(n => n.id !== selectedNote.id));
          }
        }
      } catch (error) {
        console.error(error.message);
        createInfo('error', 'Failed to delete note');
      }
      resetModal();
    };

    return (
      <form onSubmit={destroyNote}>
        <div className="mb-3">
          Are you sure you want to delete <span className='font-medium'>"{selectedNote.body}"</span>?
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Delete note</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const openDeleteNoteModal = (note) => {
    setShowModal(true)
    setSelectedNote(note)
  }

  const resetModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    setLoadingNotes(true)
    can('access nurse notes') ? getAllNotes() : getGeneralNotes()
  }, [])

  useEffect(() => {
    setLoadingNotes(true)
    can('access nurse notes') ? getAllNotes() : getGeneralNotes()
  }, [refreshNotes])

  return (
    <>
      {showModal && (
        <Modal 
            title={'Delete Note'} 
            body={<DeleteNoteElement />} 
            show={resetModal} 
        />
      )}
      <div className="w-full">
        <div className="bg-white rounded-xl p-4 mb-8 shadow-md">
            <div className="flex items-center mb-2">
              <h2 className="font-bold text-xl">General Notes</h2>
              {loadingNotes && <FontAwesomeIcon icon={faSpinner} className="animate-spin ml-2" />}
            </div>
          {generalNotes && generalNotes.map((note) => (
            <div key={note?.id} className="w-full flex justify-between p-4 mb-8 border-b border-gray-300">
              <div>
                <p className="text-sm text-gray-600 font-semibold mb-1">{note?.created_by_name} - {moment(note?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
                <p className="text-sm text-gray-600">{note?.body}</p>
              </div>
              <div>
                <button className="text-red-500 hover:text-red-700" onClick={() => openDeleteNoteModal(note)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))}
          {!generalNotes && (
            <div className="w-full rounded-xl shadow-md mb-8">
              <p className="text-sm text-gray-600">No notes yet</p>
            </div>
          )}
        </div>
        {isNurse && (
          <div className="bg-white rounded-xl p-4 shadow-md mb-8">
            <div className="flex items-center mb-2">
              <h2 className="font-bold text-xl">Nurse Notes</h2>
              {loadingNotes && <FontAwesomeIcon icon={faSpinner} className="animate-spin ml-2" />}
            </div>
            {nurseNotes && nurseNotes.map((note) => (
              <div key={note?.id} className="w-full flex justify-between p-4 mb-8 border-b border-gray-300">
                <div>
                  <p className="text-sm text-gray-600 font-semibold mb-1">{note?.created_by_name} - {moment(note?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
                  <p className="text-sm text-gray-600">{note?.body}</p>
                </div>
                <div>
                  <button className="text-red-500 hover:text-red-700" onClick={() => openDeleteNoteModal(note)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            ))}
            {!nurseNotes && (
              <div className="w-full rounded-xl shadow-md p-4 mb-8">
                <p className="text-sm text-gray-600">No notes yet</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default RequestNotes
import { useEffect, useState } from "react";
import moment from "moment";

const PPQInfo = ({ task, staffDetails, schoolInfo, questionInfo }) => {

  const [filteredStaffDetails, setFilteredStaffDetails] = useState([])
  
  useEffect(() => {
    const forename = staffDetails.find(detail => detail.title === 'Forename')?.value;
    const surname = staffDetails.find(detail => detail.title === 'Surname')?.value;
    let title = staffDetails.find(detail => detail.title === 'Title')?.value;
    if(title === 'other') {
      title = staffDetails.find(detail => detail.title === 'Specify')?.value
    }
    let name = forename + ' ' + surname;
    let newStaffDetails = []
    staffDetails.forEach(detail => {
      if(detail.title === 'Forename') {
        let item = {
          title: 'name',
          value: name
        }
        newStaffDetails.push(item)
        return
      }
      
      if(detail.title === 'Surname') {
        return
      }
      if(detail.title === 'Title') {
        let item = {
          title: 'title',
          value: title
        }
        newStaffDetails.push(item)
        return
      }
      if(detail.title === 'Specify') {
        return
      }
      
      newStaffDetails.push(detail)
    });
    setFilteredStaffDetails(newStaffDetails)
  }, [staffDetails])

  return (
    <div className="w-full p-4 bg-white rounded-xl shadow-md mb-8">
      <div className="flex mb-8 space-x-4">
        <div className="w-1/2">
          <h2 className="font-bold text-xl mb-4">Staff Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {filteredStaffDetails && filteredStaffDetails.map((detail, index) => (
              <div key={index} className="w-full flex">
                <p className="text-sm text-gray-600 mr-2 font-bold">{detail.title}: </p>
                <p className="text-sm text-gray-600">{detail.value}</p>
              </div>
            ))}
            {!staffDetails && (
              <div className="w-full">
                <p className="text-sm text-gray-600">Loading Staff Details</p>
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <h2 className="font-bold text-xl mb-4">School Information</h2>
          <div className="grid grid-cols-1 gap-2">
            {schoolInfo && schoolInfo.map((info, index) => (
              <div key={index} className="w-full p-1 flex">
                <p className="text-sm text-gray-600 mr-2 font-bold">{info.title}: </p>
                <p className="text-sm text-gray-600">{info.value}</p>
              </div>
            ))}
            {!schoolInfo && (
              <div className="w-full">
                <p className="text-sm text-gray-600">Loading School Info</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        <h2 className="font-bold text-xl mb-4">Question Information</h2>
        <div>
          {questionInfo && questionInfo.map((question, index) => (
            <div key={index} className="border-b border-gray-100">
              <div className="flex justify-between gap-x-6 py-5">
                <div className="w-2/3">
                  <p className="text-sm/6 font-semibold text-gray-900">{question.title} :</p>
                </div>
                <div className="w-1/3">
                  <p className={`mt-1 text-xs/5 ${question.value === 'Yes' ? 'text-red-600' : question.value === 'No' ? 'text-green-600' : 'text-gray-500'}`}>
                    {question.value}
                  </p>
                </div>
              </div>
            </div>
          ))}
          {!questionInfo && (
            <div className="w-full">
              <p className="text-sm text-gray-600">Loading Question Info</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

export default PPQInfo;

import { useState, useEffect } from 'react';

import MainTitle from '../../components/MainTitle';
import WebinarsGrid from './WebinarsGrid';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../components/Modal'
import CreateWebinar from './Partials/CreateWebinar';
import EditWebinar from './Partials/EditWebinar';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Webinars = () => {
  const axios = useAxiosPrivate();
  
  const [showModal, setShowModal] = useState(false);
  const [createWebinar, setCreateWebinar] = useState(false);
  const [editWebinar, setEditWebinar] = useState(false);
  const [webinarToEdit, setWebinarToEdit] = useState(null);
  
  const [webinars, setWebinars] = useState([]);

  useEffect(() => {
    getWebinars();
  }, []);

  const getWebinars = async () => {
    try {
      const response = await axios.get('/webinars');
      if(response.data) {
        setWebinars(response.data);
      } else {
        console.info('No courses found');
        setWebinars([]);
      }
    } catch (err) {
      console.error(err);
    }
  }
  
  const handleWebinarCreation = (newWebinars) => {
    setWebinars(newWebinars);
  };

  const resetModal = () => {
    setShowModal(false)
    setCreateWebinar(false)
  }

  const openModal = () => {
    setShowModal(true);
    setEditWebinar(false);
    setCreateWebinar(true);
  }

  const updateWebinar = (reportId) => {
    setCreateWebinar(false);
    setEditWebinar(true);
    //loop through webinars and find one
    let index = webinars.findIndex(webinar => webinar.report_id === reportId);
    setWebinarToEdit(webinars[index]);
    setShowModal(true);
  }

  const ModalBody = () => {
    if (createWebinar) {
      return <CreateWebinar onWebinarCreation={handleWebinarCreation} resetModal={resetModal} />
    } else if(editWebinar) {
      return <EditWebinar resetModal={resetModal} webinar={webinarToEdit} />
    }
  }

  return (
    <section className='text-gray-700'>
      {showModal && (
        <Modal title={'Add Course'} body={<ModalBody />} />
      )}
      <MainTitle title="Upcoming Courses" />
      <div>
        <div className="flex justify-end m-4">
          <button 
            className="btn ml-3" 
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
            >
            <FontAwesomeIcon icon={faCirclePlus} className="mr-2" />
            Add Course
          </button>
        </div>
        <WebinarsGrid webinars={webinars} updateWebinar={updateWebinar} />
      </div>
    </section>
  );
}

export default Webinars;

import moment from 'moment';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';

const WebinarsGrid = ({webinars, updateWebinar}) => {
  const axios = useAxiosPrivate();
  const { createInfo } = useAuth()

  const [sendingEmails, setSendingEmails] = useState([]);

  const sendInvitations = async (webinar) => {
    try {
      setSendingEmails(prev => [...prev, webinar.report_id]);
      const response = await axios.post('/webinars/send-invites', {
        report_id: webinar.report_id,
        title: webinar.title,
        course_invite_link: webinar.course_invite_link,
        start_date: webinar.start_date
      });
      if(response.data.error !== true) {
        if(!response.data.success && response.data.message === 'Course invite link is required') {
          createInfo('error', 'Course invite link is required');
        } else {
          createInfo('success', 'Invitations sent successfully');
        }

        setSendingEmails(prev => prev.filter(id => id !== webinar.report_id));
      } else {
        createInfo('error', 'Encountered an issue while sending');
        setSendingEmails(prev => prev.filter(id => id !== webinar.report_id));
      }
    } catch (err) {
      console.error(err);
      createInfo('error', 'Encountered an issue while sending');
      setSendingEmails(prev => prev.filter(id => id !== webinar.report_id));
    }
  }

  return (
    <section className='text-gray-700 pb-4'>
      {webinars && (
        <div>
          <h1 className="text-xl p-4 font-semibold">Upcoming Courses</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4 lg:gap-6 2xl:gap-12 mx-4 mb-2 pb-8 border-b border-gray-300">
            {webinars.filter(webinar => !webinar.recurring).length > 0 ? (
              webinars.filter(webinar => !webinar.recurring).map((webinar, index) => (
                <div key={index} className="bg-white rounded-xl shadow-md block transform transition-transform">
                  <div className="bg-nav text-white p-4 rounded-t-xl flex items-center">
                    <div className="flex items-center">
                      <img src="/images/swb-logo-heart.png" alt="logo" className="h-8 mr-2" />
                      <p className="text-md font-semibold ml-auto">{webinar.report_id}</p>
                    </div>
                    <div className="flex items-center ml-auto">
                      <p className="text-sm ml-4">{moment(webinar.start_date).format('MMMM Do YYYY, h:mm a')}</p>
                      <button onClick={() => updateWebinar(webinar.report_id)} className="ml-4">
                        <FontAwesomeIcon icon={faPenToSquare} className="text-xl" />
                      </button>
                    </div>
                  </div>
                  <div className="p-4">
                    <h2 className="text-xl mx-2 font-bold">
                      {webinar.title}
                    </h2>
                    <div className="mx-2 py-4">
                      <p>
                        Course Link: <a href={webinar.course_invite_link} target="_blank" rel="noopener noreferrer" className="text-blue-500">{webinar.course_invite_link}</a>
                      </p>
                    </div>
                    <div className="mx-2 py-4 border-t border-gray-300">
                      <p className="mb-2">Responses Received: {webinar.responses_count}</p>
                      <p>Tasks Created For: {webinar.task_count}</p>
                    </div>
                    <div className="w-full flex justify-end mt-2">
                      <button 
                        onClick={() => sendInvitations(webinar)} 
                        className={`px-4 py-2 rounded transition ${
                          sendingEmails.includes(webinar.report_id)
                            ? 'bg-gray-400 text-white cursor-not-allowed'
                            : 'bg-violet-400 text-white hover:bg-blue-600'
                        }`}
                        disabled={sendingEmails.includes(webinar.report_id)}
                      >
                        {sendingEmails.includes(webinar.report_id) ? 'Sending...' : 'Manual Send'}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Courses Found</p>
            )}
          </div>
          <h1 className="text-xl p-4 font-semibold">Recurring Courses</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4 lg:gap-6 2xl:gap-12 mx-4 mb-2 pb-8">
            {webinars.filter(webinar => webinar.recurring).length > 0 ? (
              webinars.filter(webinar => webinar.recurring).map((webinar, index) => (
                <div key={index} className="bg-white rounded-xl shadow-md block transform transition-transform">
                  <div className="bg-nav text-white p-4 rounded-t-xl flex items-center">
                    <div className="flex items-center">
                      <img src="/images/swb-logo-heart.png" alt="logo" className="h-8 mr-2" />
                      <p className="text-md font-semibold ml-auto">{webinar.report_id}</p>
                    </div>
                    <div className="flex items-center ml-auto">
                      <p className="text-sm ml-4">{moment(webinar.start_date).format('MMMM Do YYYY, h:mm a')}</p>
                      <button onClick={() => updateWebinar(webinar.report_id)} className="ml-4">
                        <FontAwesomeIcon icon={faPenToSquare} className="text-xl" />
                      </button>
                    </div>
                  </div>
                  <div className="p-4">
                    <h2 className="text-xl mx-2 font-bold">
                      {webinar.title}
                    </h2>
                    <div className="mx-2 py-4">
                      <p>
                        Course Link: <a href={webinar.course_invite_link} target="_blank" rel="noopener noreferrer" className="text-blue-500">{webinar.course_invite_link}</a>
                      </p>
                    </div>
                    <div className="mx-2 py-4 border-t border-gray-300">
                      <p className="mb-2">Responses Received: {webinar.responses_count}</p>
                      <p>Tasks Created For: {webinar.task_count}</p>
                    </div>
                    <div className="w-full flex justify-end mt-2">
                      <button 
                        onClick={() => sendInvitations(webinar)} 
                        className={`px-4 py-2 rounded transition ${
                          sendingEmails.includes(webinar.report_id)
                            ? 'bg-gray-400 text-white cursor-not-allowed'
                            : 'bg-violet-400 text-white hover:bg-blue-600'
                        }`}
                        disabled={sendingEmails.includes(webinar.report_id)}
                      >
                        {sendingEmails.includes(webinar.report_id) ? 'Sending...' : 'Manual Send'}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Courses Found</p>
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default WebinarsGrid
import moment from "moment";
import { useState, useEffect } from "react";

const WebinarInfo = ({ task }) => {

    const [webinarInfo, setWebinarInfo] = useState([]);
    const [reasonForTask, setReasonForTask] = useState('');
    const [webinarTitle, setWebinarTitle] = useState('');

    const getWebinarInfo = () => {
        const formData = task.task_info;
        let array = [];
        for (const [key, value] of Object.entries(formData)) {
            if(key === 'message') {
                setReasonForTask(value);
            } else {
                if(key === 'I have read and accepted the privacy policy') {
                    continue;
                }

                if(key === 'webinar_title') {
                    setWebinarTitle(value);
                    continue;
                }

                if(key === 'Date of Birth') {
                    array.push({
                        title: key,
                        value: moment(value).format('DD/MM/YYYY')
                    });
                } else {
                    array.push({
                        title: key,
                        value: value
                    });
                }
            }
        }
        setWebinarInfo(array);
    }
    
    useEffect(() => {
        getWebinarInfo();
    }, [task])

    return (
        <div className="w-full p-4 bg-white rounded-xl shadow-md mb-8">
            <div>
                <h2 className="font-bold text-xl mb-4">Course Title: {webinarTitle && webinarTitle}</h2>
                <h2 className="font-bold text-xl mb-4">Staff Details</h2>
                <div className="grid gap-2">
                    {webinarInfo && webinarInfo.map((info, index) => (
                        <>
                            {info.title !== 'report_id' && info.title !== 'response_id' && info.title !== 'webinar_title' && (
                                <div key={index} className="w-full flex">
                                    <p className="text-sm text-gray-600 mr-2 font-bold">{info.title}: </p>
                                    <p className="text-sm text-gray-600">{info.value}</p>
                                </div>
                            )}
                        </>
                    ))}
                    {!webinarInfo && (
                        <div className="w-full">
                            <p className="text-sm text-gray-600">Loading Request Details</p>
                        </div>
                    )}

                    {reasonForTask && (
                        <>
                            <div className="w-full flex mt-4">
                                <p className="text-md text-gray-600 mr-2 font-bold">Reason For Task: </p>
                                <p className="text-md text-gray-600">{reasonForTask}</p>  
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default WebinarInfo
import { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import moment from 'moment';

const EditWebinar = ({resetModal, webinar}) => {
    const { setShowLoader } = useAuth()
    const axios = useAxiosPrivate()

    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState('');
    const [recurring, setRecurring] = useState('');
    const [reportId, setReportId] = useState('');
    const [courseInviteLink, setCourseInviteLink] = useState('');

    useEffect(() => {
        setTitle(webinar.title)
        setStartDate(webinar.start_date)
        setRecurring(webinar.recurring)
        setReportId(webinar.report_id)
        setCourseInviteLink(webinar.course_invite_link)
    }, [webinar])
    
    const update = async (e) => {
        e.preventDefault()
        setShowLoader(true)

        let data = {
            title: title,
            start_date: startDate,
            recurring: recurring,
            report_id: reportId,
            course_invite_link: courseInviteLink
        }

        try {
            const response = await axios.post(`webinars/update/${webinar.report_id}`, data);

            if (response.data.error !== true) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error)
        }
        setShowLoader(false)
    }

    useEffect(() => {
      console.log(recurring)
    }, [recurring])

    return (
      <form onSubmit={update}>
        <div className="mb-3">
          <label
            htmlFor="title"
            className="block font-medium text-gray-600 text-sm"
          >
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <select
                name="title"
                id="title"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required>
              <option value=""> - Please Select - </option>
              <option value="3 Week Weight Management Programme">3 Week Weight Management Programme</option>
              <option value="4 Week Mastering Mindfulness Programme">4 Week Mastering Mindfulness Programme</option>
              <option value="6 Week Wellness Programme">6 Week Wellness Programme</option>
            </select>
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Start Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              name="date"
              id="date"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              defaultValue={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3 flex items-center">
          <div className="mt-1 relative rounded-md mr-2">
            <input
              type="checkbox"
              name="recurring"
              id="recurring"
              defaultChecked={webinar.recurring}
              onChange={(e) => setRecurring(e.target.checked)}
            />
          </div>
          <label
            htmlFor="recurring"
            className="block font-medium text-gray-600 text-sm"
          >
            Is this a recurring course?
          </label>
        </div>
        <div className="mb-3">
          <label
            htmlFor="report_id"
            className="block font-medium text-gray-600 text-sm"
          >
            Report ID
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="text"
              name="report_id"
              id="report_id"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              defaultValue={reportId}
              onChange={(e) => setReportId(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="course_invite_link"
            className="block font-medium text-gray-600 text-sm"
          >
            Course Invite Link
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="text"
              name="course_invite_link"
              id="course_invite_link"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              defaultValue={courseInviteLink}
              onChange={(e) => setCourseInviteLink(e.target.value)}
            />
          </div>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Update</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
}

export default EditWebinar
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import moment from 'moment'

const CreateAppointment = ({task, resetModal}) => {
    const axios = useAxiosPrivate();
    const { createInfo, can } = useAuth()
    
    const [selectedUser, setSelectedUser] = useState('');
    const [availability, setAvailability] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [slots, setSlots] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [usersWithAvailability, setUsersWithAvailability] = useState([])
  
    useEffect(() => {
      getUsersWithTwoWeekAvailability()
    }, [])

    const getUsersWithTwoWeekAvailability = async () => {
      try {
        const response = await axios.get('/users/all-with-two-week-availability')
        if (response.data.result && response.data.result.length > 0) {
          setUsersWithAvailability(response.data.result)
        } else {
          console.warn('No users found')
        }
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }
  
    const getAvailability = async (userId) => {
        setSelectedUser(userId)

        if (userId) {
            try {
                const response = await axios.post(`/availabilities/two-week-availability/${userId}`);
                let availabilityArray = [];

                availabilityArray = Object.entries(response.data.result[0].slots).map(([key, value]) => {
                    return {date: key, slots: value};
                });
                setAvailability(availabilityArray);
            } catch (error) {
                console.error('Error fetching availability:', error);
            }
        }
    }

    const getSlots = (date) => {
    setSlots(availability.find(item => item.date === date).slots);
    }

    const createAppointment = async (e) => {
        e.preventDefault();
        try {
            const task_info = task.task_info;
            const request_information = task_info.request_information;
            const claimReference = request_information.claim_reference;
            const response = await axios.post(`/appointments/create`, {
                user_id: selectedUser,
                appointmentable_type: 'App\\Models\\TaskList',
                appointmentable_id: task.id,
                claimReference: claimReference,
                start_date: selectedDate + ' ' + selectedTime,
                end_date: moment(selectedDate + ' ' + selectedTime).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            });

            if (response.data.error === false) {
                createInfo('success', `Appointment Created`);
            }
        } catch (error) {
            console.error(error.message);
            createInfo('error', `Failed to create Appointment`);
        }
    };

    return (
    <form onSubmit={(e) => e.preventDefault()}>
    <select id="userSelect" required className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500" onChange={(e) => getAvailability(e.target.value)} value={selectedUser}>
        <option value="">Select a user</option>
        {usersWithAvailability.map(user => (
        <option key={user.id} value={user.id}>{user.name}</option>
        ))}
    </select>

    {selectedUser && (
        <div className="mt-4">
        <div className="mb-2">
            <label htmlFor="dateSelect" className="block font-medium text-gray-600 text-sm">
            Select a date
            </label>
        </div>
        <select id="dateSelect" required className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500" onChange={(e) => {setSelectedDate(e.target.value); getSlots(e.target.value)}} value={selectedDate}>
            <option value="">Select a date</option>
            {availability && availability.map(value => (
            <option value={value.date}>{moment(value.date).format('DD/MM/YYYY')}</option>
            ))}
        </select>

        {selectedDate && (
            <div className="mt-2">
            <div className="mb-2">
                <label htmlFor="timeSelect" className="block font-medium text-gray-600 text-sm">
                Select a time
                </label>
            </div>
            <select id="timeSelect" required className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500" onChange={(e) => setSelectedTime(e.target.value)} value={selectedTime}>
                <option value="">Select a time</option>
                {slots && slots.map(time => (
                <option value={time}>{moment(time, 'HH:mm:ss').format('HH:mm')}</option>
                ))}
            </select>
            </div>
        )}
        </div>
    )}
    
    <div className="flex items-center mt-2">
        <button type="submit" className="btn mt-4 mr-4 w-1/2" onClick={(e) => {e.preventDefault(); createAppointment(e)}}>
            Create Appointment
        </button>

        <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>
        Cancel
        </button>
    </div>
    </form>
    )
}

export default CreateAppointment
/* eslint-disable */
import { useState, useEffect} from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import MainTitle from '../../../components/MainTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import OrganisationNav from '../../../components/Admin/OrganisationNav'
import PolicyCards from '../../../components/PolicyCards'

const Client = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [organisationData, setOrganisationData] = useState({
    name: '',
    reference: 'HUB-1',
    kis_link: '',
    wba_link: ''
  })

  const { setShowLoader } = useAuth()
  const [history, setHistory] = useState([])
  const [selectedHistory, setSelectedHistory] = useState()


  const getOrganisationById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/organisations/${id}`)
      setOrganisationData(response.data.result)
      setHistory(response.data.result.policy_information)
      setSelectedHistory(response.data.result.policy_information[0])
      setShowLoader(false)
    } catch (error) {}
  }


  useEffect(() => {
    getOrganisationById(id)
  }, [id])

  return (
    <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <h2 className="font-bold text-md mb-2 mx-3">Policy History</h2>
        {history !== null ? (
          <>
            <ul className="flex flex-col md:flex-row flex-wrap list-none px-0 mb-4 mx-3">
              {history?.map((item) => {

                let className = "tab-btn bg-gray-200 mb-4";
                if(selectedHistory && item.reference === selectedHistory.reference) {
                  className += " active";
                }

                return (
                  <li key={item.id} className={className} onClick={() => {selectedHistory && selectedHistory.id === item.id ? setSelectedHistory(null) : setSelectedHistory(item)}}>
                    {item.reference}
                  </li>
                )})
              }
            </ul>
            {selectedHistory && (
              <div className="flex flex-col">
                <PolicyCards policy={selectedHistory} isKis={false} />
              </div>
            )}
          </>
        ) : (<p>There is no History for this organisation</p>) }
        <hr className="mt-4 pb-4" />           
    </section>
  )
}

export default Client


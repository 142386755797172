import { useState, useEffect } from "react";
import RequestNotes from "./Partials/RequestNotes";
import TaskActions from "./Partials/TaskActions";
import WebinarInfo from "./Partials/WebinarInfo";
import TaskTitle from "../../components/TaskTitle";

const WebinarTaskView = ({task}) => {

    const [taskTitle, setTaskTitle] = useState('')
    const [refreshTrigger, setRefreshTrigger] = useState(0)

    useEffect(() => {
        setTaskTitle(task.title);
    }, [task])

    const refreshNotes = () => {
      setRefreshTrigger(refreshTrigger + 1)
    }

    return (
        <>
            <div className='text-gray-600 max-w-[74rem] 2xl:max-w-none'>
            <div className='w-full'>
                <TaskTitle
                    task={task}
                    title={taskTitle}
                    backButton
                />
            </div>
            <div className='flex w-full space-x-4'>
                <div className="w-2/3">
                    <WebinarInfo task={task} />
                </div>
                <div className='w-1/3'>
                    <TaskActions task={task} onCreatedNote={refreshNotes}/>
                    <RequestNotes task={task} refreshNotes={refreshTrigger} />
                </div>
            </div>
            </div>
        </>
    )
}

export default WebinarTaskView